:root{
    --primary-color: #cc3a13;
    --color: #1d1e1e;
    --hover-color: #722f06;
}
body {
    font-family: Karla, sans-serif;
    line-height: 22px;
    font-size: 16px;

    color: #333333;
    margin: 0;
    padding: 0;
	background: #F5F5F5;
}
.alignright {
  margin-top: 0;
  margin: 0 0 18px 24px;
  @media (min-width: 482px) {
    float: right;
    margin-left: 24px;
  }
}
.wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;
  padding: 0 15px;
  position: relative; 
  p {
    margin-bottom: 18px;
  }
}
  .site-title{
    a {
        font-size: 22px;
        font-weight: 700;
        &:hover{
            text-decoration: none;
            color: var(--primary-color);
        }
    }
  }
  .header {
      padding: 18px 24px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  .main-nav{
    display: flex;
    justify-content: space-between;
    ul {
      display: none;
      transition: all ease 0.4s;
      position: relative;
      top: 90px;
      opacity: 0;
      li {
        display: inline-block;
        margin-left: 15px;
        a {
          color: #064972;
          font-size: 17px;
          line-height: 31px;
          font-weight: 400;
          &:hover {
            color: var(--hover-color);
            text-decoration: none;
          }
        }
      }
    }
    .toggle-btn {
      cursor: pointer;
      .close{
        display: none;
      }
      @media (min-width: 992px){
        display: none;
      }
    }
    &.active {
      .toggle-btn {
        .svg-btn {
          display: none;
        }
        .close{
          display: block;
        }
      }
      ul {
        display: block;
        position: absolute;
        right: 0;
        background: #000;
        z-index: 9;
        width: 100%;
        top: 70px;
        padding: 20px 0;
        opacity: 1;
        li {
          display: block;
          a {
            color: #333333;
          }
        }
      }
    }
  }
  @media (min-width: 992px) {
    .main-nav {
      ul {
        display: block;
        position: static;
        opacity: 1;
      }
    }
  }
  .footer {
    padding: 18px 0;
  }
  img {
    max-width: 100%; 
  }
a {
    color: var(--primary-color); 
    text-decoration: none;
    transition: all 0.1s ease;
    &:hover{
        color: var(--hover-color);
        text-decoration: 1px solid underline;
    }
}
table, th, td {
  border-bottom: 1px solid;
  border-collapse: collapse;
}
table {
  width: 100%;
}
  ul,li{
      list-style: none;
      padding: 0;
      margin: 0;
	    	  margin-left: 10px;
  }
  
  h1, h2, strong {
    color: #111; 
  }
  
  h1 {
    font-size: 1.6em;
  }
  
  h2, h3 {
    font-size: 1.3em; 
    margin-top: 1.3em;
    margin-bottom: 0.2em; 
  }
  
  h3 {
    font-size: 1.1em; 
    font-style: italic; 
  }
  
  p {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  
  li {
    margin-top: 0.5em;
    margin-bottom: 0.66em;
    list-style-type: square;
  }
  
  hr {
    border: 0px;
    border-top: 1px solid #eee; 
  }
  
  .muted {
    color: #aaa;
    text-decoration: none; 
  }
  
  .small {
    font-size: .8em; 
  }